import React from "react"
import Layout from "../components/layout"
import SearchBar from "../components/searchbar"
import SEO from "../components/seo"
import IMG from "../images/ScreenShot.jpg"
import "../components/style.css"

const FAQ = () => (
  <Layout>
    <SEO title="Global Facebook Search" />
    <div className="container">
      <div className="centered">
        <div className="style1">
          <h1 className="style1">Search the Entire World</h1>
          <div>Items for Sale</div>
        </div>
        <div className="style2">Remove the 100-mile boundary</div>
        <input type="submit" value="Learn More" className="button1" />
      </div>
    </div>

    <h1>FAQs</h1>

    <div className="countrybar">
      <div className="about">
        <h3>The website is not working</h3>
        <p>
          The site works best on Google Chrome and you must be logged into
          Facebook to view Marketplace listings.
        </p>

        <h3>I don't see many results</h3>
        <p>
          Try switching the slider on Marketplace to 500 miles before performing
          a search.
        </p>
        <img src={IMG} alt="New Facebook, Classic" />

        <h3>I only see results once</h3>
        <p>
          You must return to searchworldmarketplace.com to perform a second
          search.
        </p>

        <h3>How do I receive an item that is far away?</h3>
        <p>Items can be shipped with carriers like USPS and FedEx.</p>

        <h3>What about payment?</h3>
        <p>
          Facebook offers payment services; however, PayPal is recommended with
          buyer protection.
        </p>

        <h3>Can I trust the sellers?</h3>
        <p>
          This is up to your discretion, especially if you are asking a seller
          to ship an item to you sight unseen.
        </p>

        <h3>Who made this website?</h3>
        <p>Nathan Johnson - Milwaukee, Wisconsin. See contact page.</p>

        <h3>How did you do it?</h3>
        <p>A top secret algorithm.</p>

        <h3>Are you affiliated with Facebook?</h3>
        <p>Search World Marketplace is not at all affiliated with Facebook.</p>
      </div>
    </div>

    <SearchBar />
  </Layout>
)

export default FAQ
